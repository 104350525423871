@use "../../../../../styles/etc/env" as *;
$mttBaseURL: "assets/custom_pages/group-tickets/img/staff/styled/";

.staffCardsBlock {
    :global {
        color: #fff;
        padding: 1rem 0;
        padding-bottom: 3.5em;

        h2 {
            padding-bottom: 2rem;
        }
    }
}

.staffCard {
    :global {
        .team-item {
            &::before {
                content: "";
                display: block;
                padding-bottom: 160%;
            }

            &::after {
                display: none;
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                top: 0;
                background: transparent linear-gradient(180deg, #0c234000 40%, #000000db 100%) 0% 0% no-repeat padding-box;
                z-index: 0;
            }

            position: relative;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            margin: 1rem;
            border-radius: 0.375rem;
            overflow: hidden;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

            &[class~="ethan-harnish.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}ethan-harnish.jpg");
            }
            &[class~="samantha-vansickle.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}samantha-vansickle.jpg");
            }

            &[class~="nick-vallot.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}nick-vallot.jpg");
            }

            &[class~="josten-boyer.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}josten-boyer.jpg");
            }

            &[class~="jordan-cobb.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}jordan-cobb.jpg");
            }

            &[class~="cody-thomas.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}cody-thomas.jpg");
            }

            &[class~="luca-lemee.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}luca-lemee.jpg");
            }

            &[class~="ph.jpg"] {
                background-image: url("#{$s3BaseURL}#{$mttBaseURL}ph.jpg");
            }

            .meta {
                position: absolute;
                min-height: 230px;

                right: 0;
                bottom: 0;
                left: 0;
                padding: 1.5rem;
                padding-top: 1.25rem;
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                background: #fff;
                border-bottom-left-radius: 0.375rem;
                border-bottom-right-radius: 0.375rem;

                .yl {
                    height: 1px;
                    background: $gold;
                    width: 40%;
                    opacity: 75%;
                    margin-bottom: 0.5rem;
                }

                .title,
                .focus {
                    span {
                        display: block;
                    }
                }

                .focus {
                    // display: none;
                    margin: 0 0 auto auto;
                    text-align: right;
                    text-transform: uppercase;
                }

                .name,
                .title {
                    color: $memphisMidnight;
                    font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    letter-spacing: 1.05px;
                    // text-shadow: 1px 1px 1px rgba(0,0,0,.15);
                }

                .name {
                    margin-bottom: .25rem;
                    line-height: 1.8rem;
                }

                .focus,
                .title,
                .phone,
                .email {
                    font-family: "Praktika Regular", Arial, Helvetica, sans-serif;
                    font-size: 1.4rem;
                    line-height: 1.1;
                }

                .focus {
                    font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
                    font-size: .9rem;
                    color: $memphisMidnight;
                    margin-left: initial;
                    margin-right: initial;
                    margin-bottom: .75rem;
                    text-align: center;
                    span{
                        padding-top: .125rem;
                    }
                }

                .title {
                    font-size: 1.1rem;
                    font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
                    margin-bottom: 0.5rem;
                }

                .phone,
                .email {
                    a {
                        color: $memphisMidnight;
                        font-size: 1.1rem;
                    }
                }

                .email {
                    margin-top: 0;
                    text-transform: lowercase;
                    line-height: 1;
                }
            }
        }
    }
}