@use "../../../../../styles/etc/env" as *;

@media (min-width: 1200px) {
    .actionButton {
        @at-root {
            a#{&}, button#{&} {
                transition: background-color 0.15s ease-in-out;
                &:hover {
                    color: $memphisMidnight;
                    background: #fff;
                }
            }
        }
    }
}
