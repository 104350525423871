@use "../../../../../styles/etc/env" as *;

.maxXL {
	max-width: 1200px;
}

.maxXXL {
	max-width: 1400px;
}

.fontBlackExtended {
	font-family: "Praktika Black Extended", Arial, Helvetica, sans-serif;
}

.formContainer {
	padding-top: 3em;
	padding-right: 1rem;
	padding-bottom: 5rem;
	padding-left: 1rem;
}

.formTitleBlock {
	position: relative;
	background-color: $memphisMidnight;
	background-size: cover;
	background-position: left center;
	padding-top: 3rem;
	padding-right: 1rem;
	padding-bottom: 3rem;
	padding-left: 1rem;
	z-index: -1;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: rgba(0, 0, 0, 0.35);
	}

	:global {
		h2 {
			font-size: 2.5rem;
			text-align: center;
		}
	}
}

.form {
	.link {
		position: relative;
		display: inline-block;
		font-family: "Praktika Regular", Arial, Helvetica, sans-serif;
		font-size: 1rem;
		font-weight: normal;
		text-decoration: none;
		box-shadow: none !important;
		text-decoration: none !important;

		&:hover {
			&::after {
				opacity: 1;
			}
		}

		&::after {
			content: "";
			transition: opacity 0.3s ease-in-out;
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			height: 1px;
			background: #7d98c1;
			opacity: 0.5;
		}
	}
}

.rules {
	margin-top: 5rem;
	margin-right: auto;
	margin-left: auto;

	li {
		font-size: 0.8rem;
		color: $gray;

		& + li {
			margin-top: 1rem;
		}
	}
}

@media (min-width: 768px) {
	.formContainer {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.formTitleBlock {
		padding-top: 5rem;
		padding-bottom: 5rem;

		h2 {
			font-size: 4rem;
		}
	}
}

@media (min-width: 1200px) {
	.formContainer {
		padding-top: 4rem;
		padding-bottom: 7.5rem;
	}
}

@media (min-width: 1920px) {
	.formContainer {
		padding-top: 6rem;
		padding-bottom: 10rem;
	}
}
