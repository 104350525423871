@use "../../../../../styles/etc/env" as *;

.formstackForm {
    strong {
        font-family: 'Praktika Bold', Arial, Helvetica, sans-serif;
    }

    :global {
        label {
            sup {
                color: rgb(255, 0, 0);
                font-family: "Praktika Black", Arial, Helvetica, sans-serif;
                padding-left: 0.25rem;
            }
        }
        a {
            &.privacy {
                position: relative;
                display: inline-block;
                font-family: "Praktika Regular", Arial, Helvetica, sans-serif;
                font-size: 1rem;
                text-decoration: none;
                color: $bealeStreetBlue !important;
                box-shadow: none !important;
                text-decoration: none !important;
                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
                &::after {
                    content: "";
                    transition: opacity 0.3s ease-in-out;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    background: $bealeStreetBlue;
                    opacity: 0.5;
                }
            }
        }
    }
}

.formMessages {
    :global {
        img {
            width: 8rem;
        }
    }
}
