@use "../../../../../styles/etc/env" as *;

@media (min-width: 992px) {
    .staffCard {
        :global {
            .team-item {
                .meta {

                    .phone,
                    .email {
                        a {
                            position: relative;
                            display: inline-block;

                            &::after {
                                content: "";
                                position: absolute;
                                right: 0;
                                bottom: -0.2rem;
                                left: 0;
                                height: 1px;
                                background: $memphisMidnight;
                                opacity: 0;
                                transition: opacity 0.3s ease-in-out;
                            }

                            &:hover {
                                text-decoration: none;

                                &::after {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}