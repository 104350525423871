@use "../../../../../styles/etc/env" as *;

.actionButton {
	@at-root {
		a#{&},
		button#{&} {
			font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
			font-weight: initial !important;
			display: inline-block;
			background-color: $gold;
			color: $memphisMidnight !important;
			text-transform: uppercase;
			padding: 0.4rem 2rem;
			font-size: 1rem;
			border: 2px $gold solid !important;
			box-sizing: border-box;
			text-decoration: none !important;
			white-space: nowrap;
			border-radius: 0.375rem;

			&:hover {
				color: $memphisMidnight;
				text-decoration: none !important;
			}
		}
	}
}
