@use "../../../../../../styles/etc/env" as *;

@mixin background() {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		z-index: -1;
		display: block;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-image: url(https://s3.grizzliesapp.com/assets/img/concrete-wall-mobile.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: scroll;
		opacity: 0.2;
	}

	@media (min-width: 992px) {
		&::before {
			background-image: url(https://s3.grizzliesapp.com/assets/img/concrete-wall.jpg);
			background-attachment: fixed;
		}
	}
}

@mixin cta() {
	font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
	font-weight: initial;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	margin: 0 auto;
	padding: 0.4rem 2rem 0.5rem;
	border: 2px $gold solid;
	border-radius: var(--bs-border-radius) !important;
	box-shadow: var(--bs-box-shadow) !important;
	color: $memphisMidnight;
	font-size: 1rem;
	transition: background-color 0.15s ease-in-out;
	background-color: $gold;

	&:hover {
		color: $memphisMidnight;
		text-decoration: none;
		background-color: #fff;
	}

	svg {
		transform: rotate(45deg) scale(2);
		transform-origin: center;
		display: inline-block;
		margin-right: 0.5rem;
	}
}
