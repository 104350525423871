@use "../../../../../styles/etc/env" as *;

@media (min-width: 768px) {
    .staffCard {
        :global {
            .team-item {
                &::before {
                    content: "";
                    display: block;
                    padding-bottom: 130%;
                }
            }
        }
    }
}