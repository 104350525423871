@media (min-width: 992px) {
    .actionButton {
        @at-root {
            a#{&}, button#{&} {
                padding-bottom: 0.5rem;
                &:hover {
                }
            }
        }
    }
}
