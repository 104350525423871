@use "./env" as *;

$breakpoints: (
	"xs": 375px,
	"sm": 576px,
	"md": 768px,
	"lg": 992px,
	"xl": 1200px,
	"xxl": 1400px,
	"xxxl": 1920px
);

$colors: (
	"gray": $gray,
	"beale": $bealeStreetBlue,
	"ada-beale-on-white": $ADA_bealeStreetBlue_onWhite,
	"midnight": $memphisMidnight,
	"gold": $gold
);

@each $breakpoint, $value in $breakpoints {
	.max-#{$breakpoint} {
		max-width: $value;
	}
}

@each $color, $value in $colors {
	.bg-#{$color} {
		color: $value;
	}

	.text-#{$color} {
		color: $value;
	}
}