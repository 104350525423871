@keyframes marquee-left {
	0% {
		left: 0;
	}
	100% {
		left: -100%;
	}
}

@keyframes marquee-right {
	0% {
		left: -100%;
	}
	100% {
		left: 0;
	}
}
